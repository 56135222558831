import { routes } from "~/constants";
export const heroBanner = {
  etab: {
    bgMob: "/img/recruteurs/banner-recruteur-mobile.jpeg",
    bgDesk: "/img/recruteurs/banner-recruteur-desktop.jpeg",
    subtitle: `La plateforme tout-en-un pour vos besoins de renforts ponctuels ou vos recrutements en CDI à Paris, en Île-de-France et à <a href='${routes.recruteurUrl.index}/${routes.recruteurUrl.lpEtabLyon}'>Lyon</a>`,
    cta: [
      {
        color: "secondary",
        text: "TROUVER UN RENFORT",
        picto: "extra-logo",
        url: {
          classic:
            "https://etablissement.extracadabra.com/#/auth/signup?utm_source=internal&utm_medium=click-button&utm_campaign=www_recruteur_hero_banner",
          sticky:
            "https://etablissement.extracadabra.com/#/auth/signup?utm_source=internal&utm_medium=click-button&utm_campaign=www_recruteur_sticky_cta",
        },
        slug: "create_account",
        isSubscribe: true,
      },
      {
        color: "transparent",
        text: "ÊTRE RAPPELÉ",
        picto: "extra-phone",
        url: `${routes.recruteurUrl.index}/${routes.recruteurUrl.demandeDeDemo}`,
        slug: "demo",
        isDemo: true,
      },
    ],
  },
  candidates: {
    bgMob: "/img/blank.jpeg",
    bgDesk: "/img/candidats/banner-candidat-desktop.webp",
    cta: [
      {
        color: "secondary",
        text: "inscris-toi",
        picto: "extra-logo",
        url: {
          classic:
            "https://candidat.extracadabra.com/#/creer-un-compte?utm_source=internal&utm_medium=click-button&utm_campaign=www_candidat_hero_banner",
          sticky:
            "https://candidat.extracadabra.com/#/creer-un-compte?utm_source=internal&utm_medium=click-button&utm_campaign=www_candidat_sticky_cta",
        },
        slug: "demo",
        isSubscribe: true,
      },
    ],
  },
  hidfLyon: {
    bgMob: "/img/candidats/lyon/background-hero-banner-mobile.png",
    bgDesk: "/img/candidats/lyon/background-hero-banner-desktop.png",
    cta: [
      {
        color: "secondary",
        text: "Rejoins-nous",
        picto: "extra-logo",
        slug: "create_account",
        popup: {
          src: "https://extracadabra-paris.typeform.com/to/LNp7v6tc",
        },
        isDemo: false,
      },
    ],
  },
  landing: {
    metro: {
      bgDesk:
        "https://static-cadabra.s3.amazonaws.com/www/landing/metro/banner_lp_metro.webp",
      title: "Vos besoins de renfort en <br />un claquement de doigts !",
      subtitle:
        "Client <span>METRO</span>, <strong>vos 3 premières heures</strong><br />de missions extra <strong>offertes !</strong>* ",
    },
    etabLyon: {
      bgDesk:
        "https://static-cadabra.s3.amazonaws.com/www/landing/etab-lyon/banner-landing-etablissement-lyon.webp",
      title: "En recherche de personnel qualifié à Lyon ?",
      subtitle:
        "Vous êtes un professionnel de l’Hôtellerie, Restauration ou de la Vente ? <br />Nous vous mettons en relation avec nos candidats indépendants<br /> en un claquement de doigts.",
    },
    jo: {
      bgDesk: "https://static-cadabra.s3.amazonaws.com/www/landing/jo/banner_lp_jo.webp",
      title: "Trouve ton emploi ou ta mission <br />pendant les JO de Paris 2024 !",
      subtitle:
        "Profite d’une occasion unique de participer à cet évènement exceptionnel. <br/>Inscris-toi et on te match avec les meilleures offres en Extra ou Intérim !",
    },
    interim: {
      bgDesk:
        "https://static-cadabra.s3.amazonaws.com/www/landing/interim/banner_lp_interim.webp",
      bgMob:
        "https://static-cadabra.s3.amazonaws.com/www/landing/interim/banner_lp_interim_mobile.jpg",
      title: "Les meilleures missions d’Intérim <br />en restauration à Paris ! ",
      subtitle:
        "Trouve ta future mission intérim en un claquement de doigts ! <br />Extracadabra est spécialisée dans les secteurs de la <br />restauration collective et de l'évènementiel.",
      cta: [
        {
          color: "secondary",
          text: "inscris-toi",
          picto: "extra-logo",
          url: {
            classic:
              "https://candidat.extracadabra.com/#/creer-un-compte?utm_source=internal&utm_medium=click-button&utm_campaign=www_lp_interim_hero_banner",
            sticky:
              "https://candidat.extracadabra.com/#/creer-un-compte?utm_source=internal&utm_medium=click-button&utm_campaign=www_lp_interim_sticky_cta",
          },
          isSubscribe: true,
        },
      ],
    },
  },
};
