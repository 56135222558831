export const getLocalStorageValueByKeyPrefix = (prefix: string) => {
  const key = Object.keys(localStorage).find((key) => key.startsWith(prefix));

  return key ? localStorage.getItem(key) : null;
};

export const shouldLoadTrackingTools = () => {
  const optInKeyPrefix = "__ph_opt_in_out";
  const optedValue = getLocalStorageValueByKeyPrefix(optInKeyPrefix);

  return optedValue === "1" || optedValue === null;
};
