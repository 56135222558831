<script setup type="ts">

const showBanner = ref(false);

useHead({
  htmlAttrs: {
    lang: 'fr'
  },
  meta:{
    name: 'viewport',
    content: 'width=device-width, initial-scale=1, maximum-scale=1'
  }
})

onMounted(() => {
  showBanner.value = !getLocalStorageValueByKeyPrefix("__ph_opt_in_out");
});
</script>
<template>
  <div>
    <NuxtPage />
    <CookieBanner v-if="showBanner" @hideBanner="showBanner = false" />
    <div id="portal-popup"></div>
  </div>
</template>
