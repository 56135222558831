export const companyRating = {
  rate: 4.5,
  logo: {
    src: "/img/logo-google.png",
    alt: "logo Google",
  },
  quotes: [
    {
      blockquote:
        '"Application simple d’utilisation et équipe toujours à l’écoute, même pour des demandes particulières ou last minute... Ils sont devenus indispensables au quotidien dans mes recherches d’extra ! 👍"',
      author: "Thomas L.",
    },
    {
      blockquote:
        '"Très utile et pratique. De nouvelles missions chaque jour avec des rémunérations très intéressantes..."',
      author: "Aymen B.",
    },
  ],
};
