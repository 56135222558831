import { createGtm } from "@gtm-support/vue-gtm";

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();

  if (process.client) {
    const gtmId = config.public.gtmId;

    if (shouldLoadTrackingTools()) {
      loadGoogleTagManager(nuxtApp, gtmId);
    }
  }
});

const loadGoogleTagManager = (nuxtApp: any, gtmId: string) => {
  nuxtApp.vueApp.use(
    createGtm({
      id: gtmId,
      enabled: true,
      debug: false,
      trackOnNextTick: true,
    }),
  );
};
