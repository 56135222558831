import { routes } from "~/constants";
export const traiteurEvenementiel = {
  popup: {
    src: "https://extracadabra-paris.typeform.com/to/NWYJ1mTB",
  },
  partnersSlider: {
    title1: "Plus de",
    title2: "10 000 entreprises",
    title3: "nous font confiance !",
    subtitle: "Traiteurs, mariages, agences événementielles, lieux de réceptions…",
  },
  qualifications: {
    title: "Ensemble, trouvons les meilleurs profils pour renforcer vos équipes",
    list: [
      {
        qualificationCategory: "Salle",
        qualificationImgSrc:
          "/img/recruteurs/bu/traiteur-evenementiel/qualification-salle.jpeg",
        qualificationList: [
          {
            name: "Maître d’hôtel",
            slug: "maitre-d-hotel",
            informations: {
              definition: {
                title:
                  "Quel est le rôle d'un Maître d'Hôtel dans le traiteur événementiel ?",
                text: "Le <strong>maître d’hôtel</strong> est l’hôte indispensable pour garantir un événement réussi ! Au cœur de l'action, les missions du maître d’hôtel sont l’organisation des prestations et l’accueil des clients avec courtoisie. Son rôle est aussi de coordonner avec efficacité le travail de l'équipe en salle, s'assurant que chaque service se déroule sans accroc. Que ce soit dans un banquet de mariage, un cocktail ou lors d'un événement d'envergure, il veille à la perfection de chaque détail. Les meilleurs profils de maître d’hôtel en événementiel sur les régions de <strong>Paris</strong> et de <strong>Lyon</strong> sont chez Extracadabra.",
              },
            },
          },
          {
            name: "Serveur",
            slug: "serveur",
            informations: {
              definition: {
                title:
                  "Comment le Serveur contribue-t-il à la réussite des événements traiteur ?",
                text: "Avec un œil attentif aux détails, le <strong>serveur</strong> prend les commandes, sert les plats et les boissons et s'assure que chaque aspect du service est impeccable rendant vos événements inoubliables ! Très à l’aise avec un plateau, le serveur doit aussi pouvoir faire des recommandations et connaître la carte sur le bout des doigts ! Avec notre communauté de milliers de serveurs qualifiés à <strong>Paris</strong> et à <strong>Lyon</strong>, nous sommes prêts à sublimer vos événements et à assurer un service de haute qualité.",
              },
            },
          },
        ],
      },
      {
        qualificationCategory: "Bar",
        qualificationImgSrc:
          "/img/recruteurs/bu/traiteur-evenementiel/qualification-bar.jpeg",
        qualificationList: [
          {
            name: "Mixologue",
            slug: "mixologue",
            informations: {
              definition: {
                title: "En quoi consiste le travail d'un Mixologue en événementiel ?",
                text: "Expert en saveurs, le <strong>mixologue</strong> transforme chaque événement en une expérience gustative mémorable. Dans l'univers du traiteur événementiel, il combine audacieusement ingrédients, techniques et présentations pour créer des cocktails exceptionnels. Au-delà de la préparation de boissons, il conseille les clients et anime le bar avec passion. Il peut être un acteur clef d’un évènement en créant du spectacle autour de ses boissons. Les meilleurs mixologues à <strong>Paris</strong> et à <strong>Lyon</strong> sont sur l’application Extracadabra.",
              },
            },
          },
          {
            name: "Barman",
            slug: "barman",
            informations: {
              definition: {
                title:
                  "Quelles sont les responsabilités d'un Barman en événements traiteur ?",
                text: "Le <strong>barman</strong> en événementiel est responsable de l’espace bar, il connaît parfaitement les stocks des différentes boissons, les bases des dosages pour effectuer certains cocktails. Son interaction avec les invités et sa capacité à créer une atmosphère festive font du barman un atout majeur de chaque occasion.",
              },
            },
          },
          {
            name: "Commis de bar",
            slug: "commis-de-bar",
            informations: {
              definition: {
                title:
                  "Quel est le rôle d'un Commis de Bar dans le service événementiel ?",
                text: "Le <strong>commis de bar</strong> assiste le barman dans la préparation et la réalisation des différentes boissons lors des événements. Il aide aussi à mettre en place le bar et à l’approvisionner. Véritable bras droit du barman ou mixologue, le commis de bar s’assure que ce dernier dispose de tout ce dont il a besoin pour assurer le service et le spectacle. Des centaines de profils de commis de bar sont disponibles en <strong>région parisienne</strong> et à <strong>Lyon</strong> pour vos événements traiteurs en extra ou intérim",
              },
            },
          },
        ],
      },
      {
        qualificationCategory: "Cuisine",
        qualificationImgSrc:
          "/img/recruteurs/bu/traiteur-evenementiel/qualification-cuisine.jpeg",
        qualificationList: [
          {
            name: "Chef",
            slug: "chef",
            informations: {
              definition: {
                title: "Informations",
                text: "Le <strong>chef</strong> est le responsable de la réussite de votre événement ou réception en cuisine. Ce n'est pas juste un cuistot, il est le maître de la cuisine ! Il possède une technique et une créativité exceptionnelle, choisit les meilleurs produits et s'assure de la qualité d’exécution des plats sortants de la cuisine.",
              },
            },
          },
          {
            name: "Chef de partie",
            slug: "chef-de-partie",
            informations: {
              definition: {
                title:
                  "Quelle est l'importance d'un Chef de Partie dans les événements traiteur ?",
                text: "Dans l'univers du <strong>traiteur événementiel</strong>, le <strong>chef de partie</strong> se distingue comme le maestro de la cuisine. Sa mission : réaliser des plats exquis, sélectionner avec soin les produits, et orchestrer la mise en place du service. Au-delà de ses compétences techniques, il applique et veille au respect des règles d'hygiène en cuisine. Un chef de partie excelle non seulement dans l'art culinaire mais possède également des qualités relationnelles essentielles pour former les commis et collaborer efficacement avec le service en salle. Ce rôle demande une harmonie parfaite entre savoir-faire gastronomique et leadership.",
              },
            },
          },
          {
            name: "Plongeur",
            slug: "plongeur",
            informations: {
              definition: {
                title:
                  "Comment un Plongeur soutient-il l'efficacité en cuisine événementielle ?",
                text: "Dans le monde du traiteur événementiel, le <strong>plongeur</strong> joue un rôle clé pour assurer une propreté impeccable en cuisine. Responsable du nettoyage des vaisselles, ustensiles et équipements, il contribue au maintien de l'hygiène et de l'ordre dans l'espace de travail. Au-delà de la plonge, il peut aussi assister dans la préparation des aliments et la gestion des déchets, s'adaptant aux besoins de l'événement.",
              },
            },
          },
        ],
      },
      {
        qualificationCategory: "Logistique et accueil",
        qualificationImgSrc:
          "/img/recruteurs/bu/traiteur-evenementiel/qualification-logistique.jpeg",
        qualificationList: [
          {
            name: "Manutentionnaire",
            slug: "manutentionnaire",
            informations: {
              definition: {
                title:
                  "Quelle est la contribution d'un Manutentionnaire en traiteur événementiel ?",
                text: "Essentiel en coulisses, le manutentionnaire en traiteur événementiel est la clé de la préparation et de l'organisation efficace des événements. Responsable du chargement, du transport et du déchargement du matériel, il assure que tout est en place et prêt à l'emploi. Sa rapidité et sa fiabilité sont indispensables pour le succès de chaque réception.",
              },
            },
          },
          {
            name: "Road",
            slug: "road",
            informations: {
              definition: {
                title: "Quel est le rôle d'un Road dans la logistique événementielle ?",
                text: "Le <strong>road</strong> en traiteur événementiel joue un rôle crucial dans la logistique des événements. Expert en organisation, il assure le transport, le montage et le démontage des équipements, garantissant le bon déroulement de chaque événement. Sa capacité à gérer les imprévus et son efficacité font de lui un élément indispensable à la réussite d'une réception.",
              },
            },
          },
          {
            name: "Hôtesses d’accueil",
            slug: "hotesses-d-accueil",
            informations: {
              definition: {
                title:
                  "En quoi l'Hôte ou l'Hôtesse d'Accueil est-il essentiel dans un événement ?",
                text: "L’<strong>hôte ou hôtesse d’accueil</strong> est chargé d’accueillir les clients d’un établissement. L’hôte ou l’hôtesse d’accueil a un rôle prépondérant car il est le premier interlocuteur qu'un visiteur rencontre en entrant dans un établissement ou un lieu. Il connaît les rouages de l’événement ou du lieu sur lequel il travaille, et est en capacité de répondre aux demandes d’informations de base, et d'orienter la clientèle. L’hôte d’accueil peut également s’occuper du vestiaire et du standard téléphonique.",
              },
            },
          },
          {
            name: "Vestiaire",
            slug: "vestiaire",
            informations: {
              definition: {
                title:
                  "Comment le Gestionnaire de Vestiaire optimise-t-il l'accueil événementiel ?",
                text: "Le <strong>gestionnaire de vestiaire</strong> en traiteur événementiel est souvent le premier point de contact pour les invités. Avec un sens aigu de l'organisation et une attention particulière au service client, il gère efficacement les effets personnels des invités, contribuant à une expérience d'accueil chaleureuse et professionnelle. Son rôle est essentiel pour assurer un début et une fin d'événement fluides et agréables.",
              },
            },
          },
        ],
      },
    ],
  },
  faqs: {
    title: "Recruteurs : Vos questions, nos réponses",
    list: [
      {
        question: "Quelles sont les qualifications proposées par Extracadabra ?",
        answer:
          "Chez Extracadabra, l'expertise de nos candidats est validée par notre système et nos équipes. De nombreuses qualifications sont disponibles : maître d’hôtel, chefs, barman, manutentionnaire, road, Hôtes / Hôtesses d'accueil… Vous trouverez forcément le profil idéal pour peaufiner l’équipe de rêve qui fera de vos évènements (séminaires, réceptions, fashion week, défilés, mariages…) un succès !",
      },
      {
        question: "Comment travailler avec Extracadabra ?",
        answer:
          "Extracadabra, c'est simple comme un claquement de doigt ! En plus de notre plateforme en ligne, vous pouvez prendre contact directement par téléphone pour un accompagnement personnalisé. Vous obtiendrez une réponse à toutes les questions que vous vous posez quels que soient vos besoins. Autrement, il ne vous reste plus qu'à créer votre compte sans engagement. Après quelques questions et 5 minutes de votre temps, votre compte sera créé et vous pourrez poster votre première demande gratuitement pour trouver le talent qu'il vous faut !",
      },
      {
        question: "Dans quelles régions Extracadabra est-il présent ?",
        answer:
          "Extracadabra a un vivier de professionnels spécialisés dans les métiers de la restauration à Paris et à Lyon. Pour des demandes en dehors de ces zones, n’hésitez pas à vous rapprocher de nos équipes.",
      },
    ],
  },
  otherCities: [
    {
      imgSrc: "/img/recruteurs/bu/photo-lyon-extracadabra.png",
      imgAlt: "Photo de la ville de Lyon - Extracadabra",
      cta: {
        url: "#",
        label: "EN SAVOIR PLUS",
      },
      title: "Trouvez les meilleurs talents <strong>à Lyon</strong> avec Extracadabra",
      description:
        "Confiez-nous vos besoins de recrutement à Lyon et bénéficiez de notre expertise pour dénicher les candidats les plus qualifiés",
    },
  ],
  leadGen: {
    webHookUrl: "https://hooks.zapier.com/hooks/catch/7999757/2rx9ltd/",
    book: {
      src: "/img/recruteurs/bu/traiteur-evenementiel/book_lead_gen.png",
      alt: "illustration du e-book Extracadabra",
    },
    file: {
      url: "https://static-cadabra.s3.eu-west-3.amazonaws.com/Extracadabra_x_Eat_is_family_Cas_client_5a2c1b7551.pdf",
      name: "Lead Gen Magazine",
    },
  },
  footer: {
    legals: {
      cancelation: `*Si vous n’annulez pas une prestation moins de 72 heures avant. <a href='${routes.cgu}'>en savoir plus</a>`,
    },
  },
};
