<template>
  <div class="cookie-banner overlay">
    <div class="cookie-banner box">
      <span class="title">{{ t("COOKIES.@TITLE") }}</span>
      <span class="description">{{ t("COOKIES.@DESCRIPTION") }}</span>
      <button class="btn btn-accept" type="button" @click="acceptCookies">
        {{ t("COOKIES.CTA.@ACCEPT") }}
      </button>
      <button class="btn btn-decline" type="button" @click="declineCookies">
        {{ t("COOKIES.CTA.@DECLINE") }}
      </button>
    </div>
  </div>
</template>

<script setup>
import posthog from "posthog-js";

const { t } = useI18n();

const emit = defineEmits(["hideBanner"]);

const acceptCookies = () => {
  posthog.opt_in_capturing();
  emit("hideBanner");
};

const declineCookies = () => {
  posthog.opt_out_capturing();
  emit("hideBanner");
};
</script>

<style scoped lang="scss">
.cookie-banner {
  font-family: "Poppins";
  &.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    z-index: 10000;
  }
  &.box {
    padding: 20px;
    text-align: left;
    border-radius: 10px;
    width: 90%;
    z-index: 10000;
    color: var(--showcase-color-grey-300);
    background-color: var(--showcase-color-additional-light);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    @include mq(desktop) {
      width: 470px;
    }
    .title {
      font-size: 14px;
      margin-top: var(--showcase-size-150);
      color: var(--showcase-color-grey-300);
    }
    .description {
      font-size: 12px;
      display: block;
      line-height: 18px;
      margin: var(--showcase-size-100) 0 var(--showcase-size-150);
    }
    .btn {
      &-accept {
        width: 100%;
        padding: var(--showcase-size-050);
        color: var(--showcase-color-additional-light);
        background-color: var(--showcase-color-primary-200);
        border: 1px solid transparent;
        border-radius: 6px;
        font-weight: 600;
      }
      &-decline {
        position: absolute;
        top: 15px;
        right: 15px;
        font-size: 10px;
        color: var(--showcase-color-grey-300);
      }
    }
  }
}
</style>
