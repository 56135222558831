import { routes } from "~/constants";
const utmParams = {
  utm_source: "website",
  utm_medium: "cta",
  utm_campaign: "www_menu",
};
const utm = new URLSearchParams(utmParams).toString();
const param = "?";
const ampersand = "&";
const cta = "_cta";
const jobs = "_jobs";
const candidat = "_candidat";
const recruteur = "_recruteur";
const login = "_login";
const signup = "_signup";
const chefDeCuisine = "_chef_de_cuisine";
const chefDePartie = "_chef_de_partie";
const vendeurEnBoulangerie = "_vendeur_en_boulangerie";
const receptionniste = "_receptionniste";
const nightAuditor = "_night_auditor";
// const employePolyvalent = "_employe_polyvalent";
const serveur = "_serveur";
// const chefDeRang = "_chef_de_rang";
const patissier = "_patissier";
// const barman = "_barman";
// const maitreDhotel = "_maitre_dhotel";
// const runner = "_runner";
const paris = "_ville_paris";
const lyon = "_ville_lyon";
const cdi = "_contrat_cdi";
const ae = "_contrat_ae";
const interim = "_contrat_interim";
const saisonnier = "_contrat_saisonnier";
const restauration = "_restauration";
const hotellerie = "_hotellerie";
const restoco = "_restoco";
const te = "_te";
const commerdeDeBouche = "_commerce_de_bouche";
const logistiqueVente = "_logistique_vente";
const showAll = "_show_all";

export const menu = {
  nav: [
    {
      label: "Travailler",
      type: "candidat",
      uid: "travailler",
      submenu: [
        {
          icon: "extra-information",
          title: "Pourquoi Extracadabra ?",
          subtitle:
            "Découvre comment travailler au<br />rythme de tes envies avec<br />l'application Extracadabra.",
          url: `${routes.candidatUrl.index}${param}${utm}${candidat}`,
        },
        {
          icon: "extra-work1",
          title: "Offres d’emploi",
          subtitle: "Trouve ton prochain job",
          url: `${routes.jobsUrl.index}${param}${utm}${jobs}`,
          uid: "offres_emploi",
          big_menu: [
            {
              title: "Métiers populaires",
              items: [
                {
                  label: "Chef de cuisine",
                  url: `${routes.jobsUrl.chefDeCuisine}${param}${utm}${jobs}${chefDeCuisine}`,
                  external: true,
                },
                {
                  label: "Chef de partie",
                  url: `${routes.jobsUrl.chefDePartie}${param}${utm}${jobs}${chefDePartie}`,
                  external: true,
                },
                {
                  label: "Pâtissier",
                  url: `${routes.jobsUrl.patissier}${param}${utm}${jobs}${patissier}`,
                  external: true,
                },
                {
                  label: "Vendeur en boulangerie",
                  url: `${routes.jobsUrl.vendeurEnBoulangerie}${param}${utm}${jobs}${vendeurEnBoulangerie}`,
                  external: true,
                },
                {
                  label: "Serveur",
                  url: `${routes.jobsUrl.serveur}${param}${utm}${jobs}${serveur}`,
                  external: true,
                },

                {
                  label: "Réceptionniste",
                  url: `${routes.jobsUrl.receptionniste}${param}${utm}${jobs}${receptionniste}`,
                  external: true,
                },

                {
                  label: "Night Auditor",
                  url: `${routes.jobsUrl.nightAuditor}${param}${utm}${jobs}${nightAuditor}`,
                  external: true,
                },
              ],
            },
            {
              title: "Par ville",
              items: [
                {
                  label: "Paris et sa région",
                  url: `${routes.jobsUrl.paris}${param}${utm}${jobs}${paris}`,
                  external: true,
                },
                {
                  label: "Lyon et sa région",
                  url: `${routes.jobsUrl.lyon}${param}${utm}${jobs}${lyon}`,
                  external: true,
                },
              ],
            },
            {
              title: "Par type d’offres",
              items: [
                {
                  label: "Auto-entrepreneur",
                  url: `${routes.jobsUrl.ae}${ampersand}${utm}${jobs}${ae}`,
                  external: true,
                },
                {
                  label: "Intérim",
                  url: `${routes.jobsUrl.interim}${ampersand}${utm}${jobs}${interim}`,
                  external: true,
                },
                {
                  label: "CDI",
                  url: `${routes.jobsUrl.cdi}${ampersand}${utm}${jobs}${cdi}`,
                  external: true,
                },
                {
                  label: "Contrat saisonnier",
                  url: `${routes.jobsUrl.saisonnier}${ampersand}${utm}${jobs}${saisonnier}`,
                  external: true,
                },
              ],
            },
          ],
          show_all: {
            label: "Voir toutes les offres",
            url: `${routes.jobsUrl.index}${param}${utm}${jobs}${showAll}`,
          },
        },
      ],
    },
    {
      label: "Recruter",
      type: "recruteur",
      uid: "recruter",
      submenu: [
        {
          icon: "extra-information",
          title: "Pourquoi Extracadabra ?",
          subtitle: "Simplifiez-vous les RH grâce à notre<br />plateforme tout-en-un !",
          url: `${routes.recruteurUrl.index}${param}${utm}${recruteur}`,
        },
        {
          icon: "extra-recrutement-more",
          title: "Recruter",
          subtitle: "Trouvez votre prochain renfort.",
          url: `${routes.recruteurUrl.index}${param}${utm}${recruteur}`,
          uid: "recrutement",
          big_menu: [
            {
              title: "Par secteur",
              items: [
                {
                  label: "Restauration",
                  url: `${routes.recruteurUrl.index}/${routes.recruteurUrl.bu.cafeRestaurant}${param}${utm}${recruteur}${restauration}`,
                },
                {
                  label: "Hôtellerie",
                  url: `${routes.recruteurUrl.index}/${routes.recruteurUrl.bu.hotellerie}${param}${utm}${recruteur}${hotellerie}`,
                },
                {
                  label: "Restauration collective",
                  url: `${routes.recruteurUrl.index}/${routes.recruteurUrl.bu.restaurationCollective}${param}${utm}${recruteur}${restoco}`,
                },
                {
                  label: "Traiteur/Événementiel",
                  url: `${routes.recruteurUrl.index}/${routes.recruteurUrl.bu.traiteurEvenementiel}${param}${utm}${recruteur}${te}`,
                },
                {
                  label: "Commerce de bouche",
                  url: `${routes.recruteurUrl.index}/${routes.recruteurUrl.bu.commerceDeBouche}${param}${utm}${recruteur}${commerdeDeBouche}`,
                },
                {
                  label: "Logistique/Vente",
                  url: `${routes.recruteurUrl.index}/${routes.recruteurUrl.bu.logistiqueVente}${param}${utm}${recruteur}${logistiqueVente}`,
                },
              ],
            },
            {
              title: "Par ville",
              items: [
                {
                  label: "Paris et sa région",
                  url: `${routes.recruteurUrl.index}${param}${utm}${recruteur}${paris}`,
                },
                {
                  label: "Lyon et sa région",
                  url: `${routes.recruteurUrl.index}/${routes.recruteurUrl.lpEtabLyon}${param}${utm}${recruteur}${lyon}`,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      label: "Ressources",
      uid: "ressources",
      submenu: [
        {
          icon: "extra-faq",
          title: "Foire aux questions",
          subtitle: "Une question ? Découvrez notre<br />centre d’aide.",
          url: `${routes.faqUrl.home}${param}${utm}`,
        },
        {
          icon: "extra-hand-shake",
          title: "Partenaires",
          url: `${routes.partnersUrl.index}${param}${utm}`,
        },
        {
          icon: "extra-money",
          title: "Simulateur de revenu pour<br />auto-entrepreneur",
          url: `${routes.simulatorCaAe}${param}${utm}`,
        },
        {
          icon: "extra-blog",
          title: "Médias",
          url: `${routes.mediaPressUrl}${param}${utm}`,
        },
      ],
    },
    {
      label: "Blog",
      uid: "blog",
      path: `${routes.blogHomeUrl}${param}${utm}`,
    },
    {
      label: "Me connecter",
      uid: "connexion",
      type: "cta",
      style: "link",
      dispatch: {
        title: "Me <strong>connecter</strong> en tant que :",
        or: "ou",
        cta: [
          {
            type: "candidat",
            url: `${routes.candidatWebAppUrl.baseUrl}${routes.candidatWebAppUrl.login}${param}${utm}${login}${cta}`,
            label: "Je suis un candidat",
            external: true,
          },
          {
            type: "recruteur",
            url: `${routes.etabWebAppUrl.baseUrl}${routes.etabWebAppUrl.login}${param}${utm}${login}${cta}`,
            label: "Je suis un recruteur",
            external: true,
          },
        ],
      },
    },
    {
      label: "Créer un compte",
      uid: "creer-un-compte",
      type: "cta",
      style: "rounded",
      dispatch: {
        title: "Me <strong>créer un compte</strong> en tant que :",
        or: "ou",
        cta: [
          {
            type: "candidat",
            url: `${routes.candidatWebAppUrl.baseUrl}${routes.candidatWebAppUrl.signup}${param}${utm}${signup}${cta}`,
            label: "Je suis un candidat",
            external: true,
          },
          {
            type: "recruteur",
            url: `${routes.etabWebAppUrl.baseUrl}${routes.etabWebAppUrl.signup}${param}${utm}${signup}${cta}`,
            label: "Je suis un recruteur",
            external: true,
          },
        ],
      },
    },
  ],
};
