export const userBenefits = {
  b2b: [
    {
      picto: {
        src: "/img/recruteurs/user-benefits/connect-to-candidates.svg",
        alt: "picto mise en relation",
      },
      title: "Rentrez en relation avec des milliers de profils.",
      description:
        "Vos missions sont pourvues en moins de 3 heures, avec des talents de confiance.",
    },
    {
      picto: {
        src: "/img/recruteurs/user-benefits/verified-candidates.svg",
        alt: "picto profil vérifiés",
      },
      title: "Travaillez avec des profils vérifiés.",
      description:
        "Nous vérifions le profil et les expériences des auto-entrepreneurs inscrits sur la plateforme.",
    },
    {
      picto: {
        src: "/img/recruteurs/user-benefits/admin-check.svg",
        alt: "picto gestion administrative",
      },
      title: "Libérez-vous l’esprit.",
      description: "Ne gérez plus la partie administrative, on s’en occupe pour vous.",
    },
    {
      picto: {
        src: "/img/recruteurs/user-benefits/favorite.svg",
        alt: "picto travailleurs favoris",
      },
      title: "Constituer votre vivier de travailleurs favoris.",
      description:
        "Retrouvez vos meilleurs profils en un clic et proposez-leurs les missions en priorité.",
    },
  ],
};
