import { applications } from "./applications";
import { routes } from "~/constants";
export const footer = {
  menu: [
    {
      title: "jobs",
      items: [
        {
          name: "Toutes les offres d'emploi",
          url: "https://jobs.extracadabra.com/?utm_source=website&utm_medium=cta&utm_campaign=www_footer",
          external: true,
        },
        {
          name: "Restauration",
          url: "https://jobs.extracadabra.com/emploi-restauration?utm_source=website&utm_medium=cta&utm_campaign=www_footer_push_qualifications",
          external: true,
        },
        {
          name: "Restauration collective",
          url: "https://jobs.extracadabra.com/restauration-collective?utm_source=website&utm_medium=cta&utm_campaign=www_footer_push_qualifications",
          external: true,
        },
        {
          name: "Évènementiel",
          url: "https://jobs.extracadabra.com/maitre-d-hotel?utm_source=website&utm_medium=cta&utm_campaign=www_footer_push_qualifications",
          external: true,
        },
        {
          name: "Hôtellerie",
          url: "https://jobs.extracadabra.com/hotellerie-restauration?utm_source=website&utm_medium=cta&utm_campaign=www_footer_push_qualifications",
          external: true,
        },
        {
          name: "Logistique",
          url: "https://jobs.extracadabra.com/vente-logistique?utm_source=website&utm_medium=cta&utm_campaign=www_footer_push_qualifications",
          external: true,
        },
        {
          name: "Vente",
          url: "https://jobs.extracadabra.com/vente-logistique?utm_source=website&utm_medium=cta&utm_campaign=www_footer_push_qualifications",
          external: true,
        },
      ],
    },
    {
      title: "recrutement",
      items: [
        {
          name: "Recruter en Île-de-France",
          url: routes.recruteurUrl.index,
          external: false,
        },
        {
          name: "Recruter à Lyon",
          url: `${routes.recruteurUrl.index}/${routes.recruteurUrl.lpEtabLyon}`,
          external: false,
        },
        {
          name: "Café et restaurant indépendant",
          url: `${routes.recruteurUrl.index}/${routes.recruteurUrl.bu.cafeRestaurant}`,
          external: false,
        },
        {
          name: "Groupe d'hôtels et restaurants",
          url: `${routes.recruteurUrl.index}/${routes.recruteurUrl.bu.hotellerie}`,
          external: false,
        },
        {
          name: "Restauration collective",
          url: `${routes.recruteurUrl.index}/${routes.recruteurUrl.bu.restaurationCollective}`,
          external: false,
        },
        {
          name: "Traiteur évènementiel",
          url: `${routes.recruteurUrl.index}/${routes.recruteurUrl.bu.traiteurEvenementiel}`,
          external: false,
        },
        {
          name: "Commerce de bouche",
          url: `${routes.recruteurUrl.index}/${routes.recruteurUrl.bu.commerceDeBouche}`,
          external: false,
        },
        {
          name: "Logistique et Vente",
          url: `${routes.recruteurUrl.index}/${routes.recruteurUrl.bu.logistiqueVente}`,
          external: false,
        },
        {
          name: "FAQ",
          url: routes.faqUrl.recruteurs,
          external: false,
        },
      ],
    },
    {
      title: "candidat",
      items: [
        {
          name: "Travailler",
          url: routes.candidatUrl.index,
          external: false,
        },
        {
          name: "Travailler en Intérim",
          url: `${routes.candidatUrl.index}/${routes.candidatUrl.lpInterim}`,
          external: false,
        },
        {
          name: "Simulateur de chiffre d'affaire",
          url: `${routes.simulatorCaAe}`,
          external: false,
        },
        {
          name: "Travailler à Lyon",
          url: `${routes.candidatUrl.index}/${routes.candidatUrl.hidfLyon}`,
          external: false,
        },
        {
          name: "Blog",
          url: routes.blogHomeUrl,
          external: false,
        },
        {
          name: "FAQ",
          url: routes.faqUrl.candidats,
          external: false,
        },
      ],
    },
  ],
  socials: {
    title: "Suivez-nous sur les réseaux sociaux",
    items: [
      {
        name: "facebook",
        url: "https://www.facebook.com/extracadabra",
      },
      {
        name: "tiktok",
        url: "https://www.tiktok.com/@extracadabra",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/extracadabra/",
      },
      {
        name: "linkedin",
        url: "https://www.linkedin.com/company/extracadabra/",
      },
    ],
  },
  apps: [
    {
      title: "TÉLÉCHARGER L’APPLICATION RECRUTEUR",
      items: [
        {
          src: applications.playStore.logo,
          alt: "Application Android Extracadabra - Recruteur",
          url: applications.playStore.etab,
        },
        {
          src: applications.appleStore.logo,
          alt: "Application iOS Extracadabra - Recruteur",
          url: applications.appleStore.etab,
        },
      ],
    },
    {
      title: "TÉLÉCHARGER L’APPLICATION CANDIDAT",
      items: [
        {
          src: applications.playStore.logo,
          alt: "Application Android Extracadabra - Candidat",
          url: applications.playStore.candidates,
        },
        {
          src: applications.appleStore.logo,
          alt: "Application iOS Extracadabra - Candidat",
          url: applications.appleStore.candidates,
        },
      ],
    },
  ],
  legals: {
    cgu: {
      name: "CGU",
      url: routes.cgu,
    },
    confidentiality: {
      name: "Charte de confidentialité",
      url: routes.confidentiality,
    },
  },
  company: {
    contact: {
      name: "Contact",
      url: "mailto:contact@extracadabra.com",
    },
    joinUs: {
      name: "Rejoins-nous",
      url: "https://welcometothejungle.com/fr/companies/extracadabra",
    },
    press: {
      name: "Medias",
      url: routes.mediaPressUrl,
    },
  },
};
